<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Account Setting</h1></div>
    </div>
    <div class="row">
      <div class="col-md-3 d-none d-md-block" id="menuWrapper">
        <AccountSettingMenu activeMenu="payout-method" />
      </div>
      <div class="col-md-9">
        <SettingTitle title="Payout Method" />
        <p>
          When you receive a payment for a reservation, we call that payment to you a “payout”. Our
          secure payment system supports several payout methods, which can be setup and edited here.
          Your available payout options and currencies differ by country.
          <a href="#" class="help-link">Visit the Payouts FAQ »</a>
        </p>
        <div class="table-responsive">
          <table class="table table-striped table-bordered" id="payout_methods">
            <thead>
              <tr class="text-truncate bg-light text-secondary">
                <th>Method</th>
                <th>Country</th>
                <th>Currencies</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr id="add_payout_method_section">
                <td colspan="5">
                  <a
                    class="btn btn-primary mr-10"
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#add-payout-method"
                    onclick="fnClearPayoutValues();fnOpenModalEvt(this);"
                    >Add Payout Method</a
                  ><span class="text-muted">Direct Deposit, PayPal, etc...</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="card">
          <div class="card-header">PAYOUT ROUTING RULES</div>
          <div class="card-body">
            <div class="card-text">
              Routing rules let you split payouts between methods, or direct payouts for certain
              properties to different methods.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingMenu from './Menu';
import SettingTitle from './SettingTitle';
export default {
  name: 'AccountSetting',
  components: { AccountSettingMenu, SettingTitle },
};
</script>

<style scoped></style>
